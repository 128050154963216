<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Брэндийн жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/brand-add">
                                    <el-button type="success" class="item">Нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item command = 'firstChar'>
                                                      Нэр А - Я
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'lastChar'>
                                                      Нэр Я - А
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'newest'>
                                                      Шинэ эхэнд
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'oldest'>
                                                      Хуучин эхэнд
                                                  </el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="brandList ? brandList.filter(data => !search || data.brand_monName.toLowerCase().includes(search.toLowerCase())) : null"
                            style="width: 100%"
                            v-loading="isLoading"
                            @cell-click="clickCell"
                            >
                            <el-table-column label="Зураг">
                              <template slot-scope="scope">
                                <el-row :gutter="10" align="middle" type="flex">
                                  <el-col :span="12">
                                    <div class="image-holder">
                                      <img :src="scope.row.brand_logo">
                                    </div>
                                  </el-col>
                                </el-row>
                              </template>
                            </el-table-column>
                            <el-table-column label="Монгол нэр"
                              prop="brand_monName"
                            ></el-table-column>
                            <el-table-column label="Англи нэр"
                              prop="brand_engName"
                            ></el-table-column>
                            <el-table-column label="Нийлүүлэгч"
                              prop="supplier_mon_name"
                            ></el-table-column>
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page.sync="currentPage"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      search: '',
      brandList: [],
      activeTabStatus: 'all',
      isLoading: false,
      pageSize: 20,
      totalCount: 0,
      currentPage: 1
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getBrands(from, size)
  },

  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'brandList', query: { page: this.currentPage, size: this.pageSize } })
      this.getBrands((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'brandList', query: { page: item, size: this.pageSize } })
      this.getBrands((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.brand_monName.localeCompare(b.brand_monName))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.brand_monName.localeCompare(a.brand_monName))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created_at.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created_at.localeCompare(b.created_at))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.brandList = this.sortMethods(data, this.brandList)
    },

    getBrands (from, size) {
      const query = '?search_text=' + this.search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getBrands(query).then(data => {
        this.brandList = data.datas
        this.totalCount = data.total
        this.isLoading = false
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'editBrand',
          params: {
            brand_id: row.brand_id
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
